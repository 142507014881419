import React from "react";
import "../src/App.css";
import { Routes , Route , NavLink , useLocation} from "react-router-dom";
import Hero from "./components/hero/hero";

const App = () => {
    return (
      <div className="main-class">
         <Hero />
      </div>
    );
}

export default App;
