import React from 'react';
import "./main.css";
import {motion} from "framer-motion"
import Nav from '../nav/nav';
const Main = () => {
    return (
        <div>
            <motion.div 
            initial={{scale:0 , backgroundColor:"#ffffff"}}
            animate={{scale:1 , backgroundColor:"#1b1a17f2"}}
            transition={{delay:2 , duration:0.3}}
            className='home position-absolute top-0 start-0 bottom-0 end-0'>
                <motion.div
                initial={{y:"-100vh"}}
                animate={{y:0}}
                transition={{delay:2,duration:0.4}}
                className='h-25 overflow-hidden'>
                    <Nav />
                </motion.div>
                <div className='bg-success h-50'>

                </div>
                <div className='bg-danger h-25'>

                </div>
            </motion.div>
        </div>
    );
}

export default Main;
