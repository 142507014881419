import React from 'react';
import "./hero.css";
import {motion} from "framer-motion"
import one from "../../assests/cotch/one.png"
import tow from "../../assests/cotch/tow.png"
import three from "../../assests/cotch/three.png"
import four from "../../assests/cotch/seven.png"
import five from "../../assests/cotch/five.png"
import six from "../../assests/cotch/six.png"
import seven from "../../assests/cotch/four.png"
import Main from '../main/main';
const Hero = () => {
    return (
        <div>
            <div className="position-relative overflow-hidden">
                {/* this is gallary player hero */}
                <div className="row container mx-auto">
                    <div className="col">
                        <motion.div
                        initial={{y:"-100vh" , background:"linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(246,107,14,0) 53%, rgba(240,104,6,0) 100%)" , boxShadow:"0px 0px 0px #f66b0e"}}
                        animate={{y:0 , background:"linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(246,107,14,1) 53%, rgba(240,104,6,0) 100%)", boxShadow:"0px 0px 20px #f66b0e"}}
                        transition={{y : {delay:0.3 , duration:0.3} , background : {delay:1.1 , duration:0.2} , boxShadow:{delay:1.1 , duration:0.2} }}
                        className='col-img d-flex justify-content-end align-items-center'>
                            <motion.img
                            initial={{opacity:0}}
                            animate={{opacity:1}}
                            transition={{delay:1.1 , duration:0.3}}  
                            src={one} />
                        </motion.div> 
                    </div>
                    <div className="col">
                        <motion.div
                        initial={{y:"100vh" , background:"linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(246,107,14,0) 53%, rgba(240,104,6,0) 100%)" , boxShadow:"0px 0px 0px #f66b0e"}}
                        animate={{y:0 , background:"linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(246,107,14,1) 53%, rgba(240,104,6,0) 100%)", boxShadow:"0px 0px 20px #f66b0e"}}
                        transition={{y : {delay:0.2 , duration:0.2} , background : {delay:0.9 , duration:0.2} , boxShadow:{delay:0.9 , duration:0.2} }}
                        className='col-img d-flex justify-content-end align-items-center'>
                            <motion.img
                            initial={{opacity:0}}
                            animate={{opacity:1}}
                            transition={{delay:0.9 , duration:0.3}}  
                            src={tow} />
                        </motion.div> 
                    </div>
                    <div className="col">
                        <motion.div
                        initial={{y:"-100vh" , background:"linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(246,107,14,0) 53%, rgba(240,104,6,0) 100%)" , boxShadow:"0px 0px 0px #f66b0e"}}
                        animate={{y:0 , background:"linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(246,107,14,1) 53%, rgba(240,104,6,0) 100%)", boxShadow:"0px 0px 20px #f66b0e"}}
                        transition={{y : {delay:0.3 , duration:0.3} , background : {delay:0.7 , duration:0.2} , boxShadow:{delay:0.7 , duration:0.2} }}
                        className='col-img d-flex justify-content-end align-items-center'>
                            <motion.img
                            initial={{opacity:0}}
                            animate={{opacity:1}}
                            transition={{delay:0.7 , duration:0.3}}  
                            src={three} />
                        </motion.div> 
                    </div>
                    <div className="col">
                        <motion.div
                        initial={{y:"100vh" , background:"linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(246,107,14,0) 53%, rgba(240,104,6,0) 100%)" , boxShadow:"0px 0px 0px #f66b0e"}}
                        animate={{y:0 , background:"linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(246,107,14,1) 53%, rgba(240,104,6,0) 100%)", boxShadow:"0px 0px 20px #f66b0e"}}
                        transition={{y : {delay:0.1 , duration:0.2} , background : {delay:0.5 , duration:2.3} , boxShadow:{delay:0.5 , duration:0.2} }}
                        className='col-img d-flex justify-content-end align-items-center'>
                            <motion.img
                            initial={{opacity:0}}
                            animate={{opacity:1}}
                            transition={{delay:0.5 , duration:0.3}}  
                            src={four} />
                        </motion.div> 
                    </div>
                    <div className="col">
                        <motion.div
                        initial={{y:"-100vh" , background:"linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(246,107,14,0) 53%, rgba(240,104,6,0) 100%)" , boxShadow:"0px 0px 0px #f66b0e"}}
                        animate={{y:0 , background:"linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(246,107,14,1) 53%, rgba(240,104,6,0) 100%)", boxShadow:"0px 0px 20px #f66b0e"}}
                        transition={{y : {delay:0.3 , duration:0.3} , background : {delay:0.7 , duration:0.2} , boxShadow:{delay:0.7 , duration:0.2} }}
                        className='col-img d-flex justify-content-end align-items-center'>
                            <motion.img
                            initial={{opacity:0}}
                            animate={{opacity:1}}
                            transition={{delay:0.7 , duration:0.3}}  
                            src={five} />
                        </motion.div> 
                    </div>
                    <div className="col">
                        <motion.div
                        initial={{y:"100vh" , background:"linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(246,107,14,0) 53%, rgba(240,104,6,0) 100%)" , boxShadow:"0px 0px 0px #f66b0e"}}
                        animate={{y:0 , background:"linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(246,107,14,1) 53%, rgba(240,104,6,0) 100%)", boxShadow:"0px 0px 20px #f66b0e"}}
                        transition={{y : {delay:0.2 , duration:0.2} , background : {delay:0.9 , duration:0.2} , boxShadow:{delay:0.9 , duration:0.2} }}
                        className='col-img d-flex justify-content-end align-items-center'>
                            <motion.img
                            initial={{opacity:0}}
                            animate={{opacity:1}}
                            transition={{delay:0.9 , duration:0.3}}  
                            src={six} />
                        </motion.div> 
                    </div>
                    <div className="col">
                        <motion.div
                        initial={{y:"-100vh" , background:"linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(246,107,14,0) 53%, rgba(240,104,6,0) 100%)" , boxShadow:"0px 0px 0px #f66b0e"}}
                        animate={{y:0 , background:"linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(246,107,14,1) 53%, rgba(240,104,6,0) 100%)", boxShadow:"0px 0px 20px #f66b0e"}}
                        transition={{y : {delay:0.3 , duration:0.3} , background : {delay:1.1 , duration:0.2} , boxShadow:{delay:1.1 , duration:0.2} }}
                        className='col-img d-flex justify-content-end align-items-center'>
                            <motion.img
                            initial={{opacity:0}}
                            animate={{opacity:1}}
                            transition={{delay:1.1 , duration:0.3}}  
                            src={seven} />
                        </motion.div> 
                    </div>
                </div>
                {/* this is main page */}
                <Main />  
            </div>
        </div>
    );
}

export default Hero;
